<template>
    <content-view>
        <create-document/>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import CreateDocument from '@/components/document/list/CreateDocument'
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: 'DocumentAdd',
    mixins: [metaTitle],
    components: { CreateDocument, ContentView }
}
</script>

<style scoped>

</style>
