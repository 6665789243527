<template>
    <div>
        <loading-spinner v-if="isLoading" />
        <div v-if='PermissionModalVisible' class='modal-backdrop fade show'></div>
        <div class='material-header'>
            <h2 class='material-header__title'>
                Добавление документа
            </h2>
        </div>
        <ValidationObserver tag='form' class='form-propose js-without-double-block' @submit.prevent='onSubmit'
                            ref='observer' method='post' enctype='multipart/form-data'>
            <div class='material-content'>
                <!--Вкладка - Материал-->
                <ValidationProvider rules='required|max_length:255' vid='name' v-slot='provider'>
                    <div class='form-group' v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class='form-material floating open'>
                            <label for='id_name'>Название документа *</label>
                            <input v-model='form.name' type='text' name='name' maxlength='255' id='id_name'
                                   class='form-control'>
                            <div id='id_name-error' class='invalid-feedback animated fadeInDown'
                                 v-if='provider.errors.length > 0'> {{ provider.errors[0] }}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    tag='fragment'
                    vid='rubric'
                    :rules="current_user.is_editor_rubric_docs && !current_user.is_editor_document && !current_user.is_admin ? 'required' : null"
                    v-slot='provider'
                >
                    <div
                        class='form-group'
                        v-bind:class="{'is-invalid': provider.errors.length > 0}"
                    >
                        <div class='form-material floating open'>
                            <label for='id_rubrics'>
                                Рубрика
                                <span
                                    v-if='current_user.is_editor_rubric_docs && !current_user.is_editor_document && !current_user.is_admin'
                                > *</span>
                            </label>

                            <v-select2
                                class='form-material'
                                id='id_rubrics'
                                label='name'
                                :reduce='name => name.id'
                                :options='listRubrics'
                                :clearable='true'
                                :searchable='true'
                                v-model='form.rubric'
                            >
                                <span slot='no-options'>Ничего не найдено</span>
                            </v-select2>

                            <div
                                v-if='provider.errors.length > 0'
                                id='id_rubrics-error'
                                class='invalid-feedback animated fadeInDown'
                            >
                                {{ provider.errors[0] }}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <div class='form-group'>
                    <div class='form-material floating open'>
                        <label for='id_materials'>
                            Материалы
                        </label>

                        <v-select2
                            class='form-material'
                            id='id_materials'
                            label='title'
                            :reduce='title => title.id'
                            :options='listMaterials'
                            :clearable='true'
                            :searchable='true'
                            v-model='form.material'
                        >
                            <span slot='no-options'>Ничего не найдено</span>
                        </v-select2>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="number_document">
                            Номер документа
                        </label>

                        <input
                            id="number_document"
                            class="form-control"
                            type="text"
                            name="number"
                            maxlength="21"
                            v-model="form.number"
                        >
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="approval_date">
                            Дата согласования
                        </label>

                        <VueDatePicker
                            id="approval_date"
                            class="form-control"
                            color="#8e8e8e"
                            placeholder="Выберите дату"
                            :validate="true"
                            :noHeader="true"
                            :noCalendarIcon="true"
                            :locale="{lang: 'ru'}"
                            :format="'DD.MM.YYYY'"
                            :clearable="true"
                            v-model="form.date_approval"
                        />
                    </div>
                </div>

                <div class='form-group'>
                    <div class='form-material floating open'>
                        <label for='id_type'>
                            Тип документа
                        </label>

                        <v-select2
                            class='form-material'
                            id='id_type'
                            :reduce='label => label.value'
                            :options='typeOption'
                            :clearable='false'
                            :searchable='false'
                            v-model='form.type'
                        >
                        </v-select2>
                    </div>
                </div>

                <ValidationProvider
                    :rules="form.type === 1 ? 'required|max_length:255' : null"
                    v-slot="provider"
                    vid="url"
                >
                    <div
                        v-show='form.type === 1'
                        v-bind:class="{'is-invalid': provider.errors.length > 0}"
                        class='form-group'
                    >
                        <div class='form-material floating open'>
                            <label for='id_url'>
                                Внешняя ссылка на документ
                            </label>
                            <input v-model='form.url' type='text' name='url' maxlength='200' id='id_url' class='form-control'>
                            <div id='id_url-error' class='invalid-feedback animated fadeInDown' v-if='provider.errors.length > 0'> {{ provider.errors[0] }}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    events="['change']"
                    :rules="form.type === 2 && form.file && form.file.length === 0 ? 'required' : null"
                    v-slot='provider'
                    vid='file'
                >
                    <div class='form-group' v-show='form.type === 2'
                         v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class='form-material floating open'>
                            <label for='id_file'>Файл</label>
                            <input v-on:change='onFileChange' type='file' name='file' id='id_file'>
                            <div id='id_file-error' class='invalid-feedback animated fadeInDown'
                                 v-if='provider.errors.length > 0'> {{ provider.errors[0] }}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <div class="row ml-0 mr-0">
                    <div class='form-material open col-12 col-md-4'>
                        <span v-show='form.permissionData.access_all'>
                            Документ доступен всем
                        </span>
                        <span v-show='infoPermissionText && !form.permissionData.access_all'>
                                <span class='material__access-txt'>{{ infoPermissionText }}</span>
                            </span>
                        <span v-show='!infoPermissionText && !form.permissionData.access_all'>
                                <span class='material__access-txt'>Доступ не настроен</span>
                            </span>
                        <a href='javascript:void(0)' class='material__access'
                           @click='changePermission'>
                            <svg width='15' height='15' viewBox='0 0 15 15'
                                 xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M13.724 1.274a4.35 4.35 0 00-6.966 5.022L.006 13.05l-.004 1.947L3.778 15v-1.836h1.836v-1.837h1.837V9.491L8.7 8.24a4.351 4.351 0 005.023-6.966zm-1.383 3.243a1.315 1.315 0 11-1.86-1.86 1.315 1.315 0 011.86 1.86z'></path>
                            </svg>
                            Настроить доступ
                        </a>
                    </div>
                </div>

                <div class='material-footer'>
                    <router-link class='material-footer__reset' to="/documents/">
                        Отмена
                    </router-link>
                    <button class='material-footer__add' type='submit'>
                        Добавить
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    import session from '@/api/session'
    import axios from 'axios'
    import { mapState } from 'vuex'
    import { eventBus } from '@/main'
    import LoadingSpinner from '@/components/LoadingSpinner.vue'
    import QuestionsList from '@/components/knowledge_base/widgets/QuestionsList'

    export default {
        name: 'CreateDocument',
        components: {
            LoadingSpinner,
            QuestionsList
        },
        data() {
            return {
                test: '',
                infoPermissionText: '',
                infoBindingText: '',
                PermissionModalVisible: false,
                resetPermissionModal: false,
                typeOption: [
                    {
                        value: 1,
                        label: 'Внешняя ссылка'
                    },
                    {
                        value: 2,
                        label: 'Выбрать документ'
                    }
                ],
                listRubrics: [],
                listMaterials: [],
                form: {
                    permissionData: { structure: {}, users: [], access_all: true },
                    bindingData: { structure: {}, users: [], access_all: true },
                    name: '',
                    rubric: '',
                    material: '',
                    type: 1,
                    url: '',
                    file: null,
                    testing: false,
                },
                isLoading: false,
                ourRequest: null
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user'
            ])
        },
        watch: {
            'form.rubric': {
                handler: function(val, oldVal) {
                    if (val) {
                        let params = {
                            permission_type: 'rubric_document',
                            permission_object_id: val
                        }
                        session.get('/api/v1/permission/retrieve_vue/', { params }).then(response => {
                            this.form.permissionData = response.data
                            this.form.permissionData.note.parentRight = true
                            this.infoPermissionText = response.data.info_text
                            this.permissionDataCache = null
                        })
                    } else {
                        this.form.permissionData = {
                            structure: {},
                            users: [],
                            access_all: true,
                            note: { parentRight: false }
                        }
                        this.infoPermissionText = 'Доступен всей компании'
                        this.dataCache = null
                    }
                },
                deep: true
            },
        },
        created() {
            session
                .get('/api/v1/material/all_list/')
                .then(response => {
                    this.listMaterials = response.data
                })
                .catch(() => {
                })
            session
                .get('/api/v1/document/rubrics/')
                .then(response => {
                    this.listRubrics = response.data
                })
                .catch(() => {
                })
        },
        methods: {
            changePermission() {
                let checkbox = this.form.rubric ? 'Как у родительской рубрики' : 'Для всей компании'

                const ModalPermissionCreate = () => import(`../../permission/ModalPermissionCreate`)
                this.$modal.show(ModalPermissionCreate, {
                    title: 'Настройка доступа к документу',
                    text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному документу',
                    checkbox: checkbox,
                    rubricID: this.form.rubric,
                    permissionTypeParent: 'rubric_document',
                    dataCache: this.permissionDataCache
                }, {
                    name: 'ModalPermissionCreate',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': this.updateTextPermission
                })
            },
            changeBinding() {
                let checkbox = this.form.rubric ? 'Как у родительской рубрики' : 'Для всей компании'

                const ModalBindingCreate = () => import(`../../permission/ModalBindingCreate`)

                this.$modal.show(ModalBindingCreate, {
                    title: 'Настройка привязки к документу',
                    text: 'Выберите отделы, должности или сотрудников, которых необходимо привязать к данному документу',
                    checkbox: checkbox,
                    rubricID: this.form.rubric,
                    permissionTypeParent: 'rubric_document',
                    dataCache: this.bindingDataCache,
                    defaultData: this.defaultDataDataCache,
                }, {
                    name: 'ModalBindingCreate',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': this.updateTextBinding
                })
            },
            updateTextPermission(data) {
                if (data.params) {
                    data = data.params.data
                    this.form.permissionData = data
                    this.permissionDataCache = data.permissionDataCache
                    this.defaultDataDataCache = JSON.parse(JSON.stringify(this.form.permissionData))
                    this.infoPermissionText = data.info_text

                    this.infoBindingText = ''
                    this.form['users_ids'] = []
                    this.form['departments_ids'] = []
                    this.form.bindingData = {structure: {}, users: [], access_all: true}
                }
            },
            updateTextBinding(data) {
                if (data.params) {
                    data = data.params.data
                    this.form.bindingData = data
                    this.form['users_ids'] = data['users']
                    this.form['departments_ids'] = Object.keys(data.structure)
                    this.bindingDataCache = data.bindingDataCache
                    this.defaultDataDataCache = null
                    this.infoBindingText = data.info_text
                }
            },
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files
                if (!files.length)
                    return
                this.form.file = files[0]
            },
            async onSubmit(e) {
                e.preventDefault()

                let isValidModal = await this.$refs.observer.validate()

                if (isValidModal) {
                    this.isLoading = true
                    let progress_bar = this.$Progress
                    let url = '/api/v1/document/'
                    let config = {
                        onUploadProgress(progressEvent) {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) /
                                progressEvent.total)
                            progress_bar.set(percentCompleted)
                            return percentCompleted
                        }
                    }
                    this.$Progress.start()
                    let data = this.form

                    if (this.form.file) {
                        let file_name = this.form.file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');

                        let fileResult = {
                            name: this.form.name,
                            url: this.form.url,
                            rubric: this.form.rubric,
                            material: this.form.material,
                            type: this.form.type,
                            file_name: file_name,
                            file_size: this.form.file.size,
                            permissionData: data.permissionData,
                            users_ids: data.bindingData.users,
                            departments_ids: Object.keys(data.bindingData.structure),
                            author: 1,
                        }

                        data = fileResult;
                    }

                    this.ourRequest = axios.CancelToken.source()

                    // сохраним "Дата согласования" в нужном формате
                    if (this.form.date_approval) {
                        const format = 'YYYY-MM-DD HH:mm:ss';
                        const date = `${this.$moment(this.form.date_approval).format('YYYY-MM-DD')} ${this.$moment('00:00:00', 'HH:mm:ss').format('HH:mm:ss')}`;

                        this.form.date_approval = this.$moment(date).format(format);
                    } else {
                        this.form.date_approval = null
                    }

                    try {
                        await this.onSaveDocument(url, data, config)
                    } catch (error) {
                        const errorResponse = error.response;

                        this.$Progress.finish()

                        if (errorResponse) {
                            if (errorResponse.status === 400) {
                                this.$swal({
                                    customClass: {
                                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                                    },
                                    title: 'Данный документ уже загружен. Заменить?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Да, заменить!',
                                    cancelButtonText: 'Нет'
                                }).then(
                                    async value => {
                                        if (value.value) {
                                            const swal = this.$swal({
                                                title: 'Заменяем...',
                                                icon: 'info',
                                                showConfirmButton: false,
                                                allowOutsideClick: false,
                                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                                            })

                                            try {
                                                data.rewrite = true;
                                                await this.onSaveDocument(url, data, config);
                                            } catch (error) {
                                                this.$swal({
                                                    title: 'Ошибка!',
                                                    text: 'Во время замены возникла ошибка. Повторите попытку.',
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    timer: 1400
                                                })
                                            }

                                            swal.close();
                                        }
                                    }
                                )
                            } else {
                                this.$refs.observer.setErrors(error.response.data)
                            }
                        }
                    }

                    this.isLoading = false
                }
            },
            async uploadFileByLink(uploadLink) {
                try {
                    this.isLoading = true
                    await axios.put(uploadLink, this.form.file,
                    {
                        cancelToken: this.ourRequest.token
                    })
                    this.isLoading = false
                } catch (e) {
                    this.isLoading = false
                    console.log('error', e)
                }
            },

            async onSaveDocument(url, data, config) {
                const response = await session.post(url, data, { cancelToken: this.ourRequest.token, ...config })
                const resData = response.data;

                if (this.form.file) {
                    await this.uploadFileByLink(resData.upload_link)
                }

                this.$Progress.finish()
                this.$router.push('/documents/');

                eventBus.$emit('load-documents-list');
            }
        }
    }
</script>

<style lang='scss'>
    @import "#sass/v-style";

    .doc-test {
        padding: 20px;
        margin: 16px 0;
        border-radius: 4px;
        border: 2px solid #e6ebf3;
        &__box {
            border-radius: 4px;
            border: 2px solid #e6ebf3;
            padding: 20px;
            & + .doc-test__box {
                margin-top: 30px;
            }
        }

        &__title {
            margin: 0 0 20px;
        }
    }
</style>
